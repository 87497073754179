<div class="dropDown-container">
    <span class="field-label" *ngIf="dropdownDetails?.label">
        <div class="field-label-container">
            {{dropdownDetails ? dropdownDetails.label : null}}
            <sup *ngIf="dropdownDetails.required">*</sup>
        </div>
        
        <div class="tooltip-container">
            <custom-tooltip *ngIf="dropdownDetails.enableTooltip" [tooltipText]="dropdownDetails.tooltipContent"></custom-tooltip>
        </div>
        
    </span>

    <div class="dropdown-container-basic" [ngClass]="{'dropdown-error' : dropdownDetails?.validation?.isError, 'dropdown-success' : dropdownDetails?.validation?.isSuccess, 'dropdown-disabled' : dropdownDetails?.isDisabled}">
        <p-dropdown [options]="dropdownDetails?.options" optionLabel="label" [(ngModel)]="dropdownDetails.selectedValue" [showClear]="false" [placeholder]="dropdownDetails?.placeholder" (onChange)="handleChangeEvent()"
        appendTo="body" [disabled]="dropdownDetails?.isDisabled"  [showClear]="dropdownDetails?.showClear" >
            
        <ng-template let-items pTemplate="item">
                <div class="item-container">
                    <span class="main-text">{{items?.label}}</span>
                    <span class="additional-text">{{items?.additionaltext}}</span>
                </div>
            </ng-template>
        </p-dropdown>    
    </div>


        <div class="validator-container" *ngIf="dropdownDetails?.validation != null">
        <validator [validatorDetails]="dropdownDetails?.validation"></validator>
        </div>

</div>
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { JourneyDetailsApiService } from 'src/app/app-v2/api-services/create-ride/journey-details-api.service';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { CreateRideService } from 'src/app/app-v2/getter-setter/container/create-ride/create-ride-container.service';
import { CustomerDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/customer-details-container.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SelectRideTypeContainerService } from 'src/app/app-v2/getter-setter/select-ride-type-container.service';
import { DateTimePipe } from 'src/app/app-v2/pipe/createridepipe.pipe';

import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-ride-subscription.service';
import { CalendarType, CustomButton, CustomDropDownType, CustomInputType } from 'src/app/app-v2/types/components.type';
import { CustomerDetails } from 'src/app/app-v2/types/container.type';
import { ButtonTypes, Theme } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'single-timestamp-container',
  templateUrl: './single-timestamp-container.component.html',
  styleUrls: ['./single-timestamp-container.component.scss']
})
export class SingleTimestampContainerComponent implements OnInit, OnDestroy{
  allSlotsData = {};
  zoneBasedLeadTime = 0;
  closeDialogSubscription: Subscription;

  timeStampContainerCta: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: "",
    rightUrl: "",
    disabled: false,
    type: ButtonTypes.FILLED
  }

  expressRideContainerCta: CustomButton = {
    label: 'Book Now',
    left: true,
    right: false,
    leftUrl: "assets/express-white-icon.svg",
    rightUrl: "",
    disabled: false,
    type: ButtonTypes.OUTLINED
  }

  timestampInputDetails: CalendarType = {
    placeholder : this._rideType.getSelectedRideTypeDetails().slotsRequired ? '(DD/MM/YYYY)' : '(DD/MM/YYYY) (HH/MM)',
    inputValue: null,
    label: !this._rideType.getSelectedRideTypeDetails().slotsRequired ? "Select Date and Time" : "DD/MM/YYYY",
    name: 'dateTime',
    validation:  null,
    isDisabled: false,
    format: 'dd/mm/yy',
    selection: 'single',
    showTime : !this._rideType.getSelectedRideTypeDetails().slotsRequired,
    minDate: new Date(),
    stepMinute: this._common.getIsAdminUser() ? 1 : 15,
    stepHour: 1,
    maxDate: this.setMaxDate(),
    required: true,
    enableTooltip: false,
    tooltipContent: '',
    timeFormat: 12
  }

  hourDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'HH',
    name: 'hourSlots',
    placeholder : 'Select Hour',
    isDisabled: true,
    required: true,
    enableTooltip: false,
    tooltipContent: ''
  }


  minDropDownDetails: CustomDropDownType ={
    selectedValue: {label: '', value: ''},
    validation:  null,
    options: [],
    label: 'MM',
    name: 'minSlots',
    placeholder : 'Select Minutes',
    isDisabled: true,
    required: true,
    enableTooltip: false,
    tooltipContent: ''
  }

  slots;
  isSlotsRequired = this._rideType.getSelectedRideTypeDetails().slotsRequired;
  isExpressRide = false;

  constructor(private _journey: JourneyDetailsContainerService,
              private _customer: CustomerDetailsContainerService,
              private _createRide: CreateRideService,
              private _journeyApi: JourneyDetailsApiService,
              private _subscription: CreateRideSubscriptionService,
              private _loader: NgxSpinnerService,
              private _rideType: SelectRideTypeContainerService,
              private _common: CommonService,
              private _commonSubscription: CommonSubscriptionService,
              private _dateTimePipe: DateTimePipe
              
  ){}

  ngOnInit(): void {
    this._journey.setSelectedZoneIdForLeadTime();
    this._common.setMaxCreateRideDate(this._rideType.getSelectedRideTypeDetails().code);
    this.timestampInputDetails.maxDate = this.setMaxDate();
    this._common.setLeadTimes();
    this.isSlotsRequired = this.checkIfSlotsRequired(this._common.getSelectedCountry());
    this.timestampInputDetails.showTime = !this.isSlotsRequired;
    this.isExpressRide = this.checkExpressRide();

    if(this.checkExpressRide()){
      this.timeStampContainerCta['type'] = ButtonTypes.OUTLINED ;
      this.expressRideContainerCta['type'] = ButtonTypes.FILLED ; 
    }
    else{
      this.timeStampContainerCta['type'] = ButtonTypes.FILLED ;
      this.expressRideContainerCta['type'] = ButtonTypes.OUTLINED ; 
    }
    
    this.fetchPrevData();
    this.startCloseDialogSubscription();  
  }


  checkExpressRide(){
    if(this.isSlotsRequired){
      return false;
    }
    else{
      return this._rideType.getIsExpressRide();
    }
  }


  checkIfSlotsRequired(country){
    switch(country){
      case 'IN':
      return this._common.getIsAdminUser() ? false : this._rideType.getIsSlotRequired();
      
      case 'AE':
      return true;
    }
  }
  // checkIfSlotsRequired(){
  //   let isPremiumRide = this._journey.getSelectedCarVariantCategory() == 'PREMIUM';
  //   this.isSlotsRequired = isPremiumRide ? true : false;
  //   this._rideType.setIsSlotRequired(this.isSlotsRequired);

  //   this.timestampInputDetails.placeholder = this.isSlotsRequired ? '(DD/MM/YYYY)' : '(DD/MM/YYYY) (HH/MM)';
  //   this.timestampInputDetails.label = !this.isSlotsRequired ? "Select Date and Time" : "DD/MM/YYYY";
  //   this.timestampInputDetails.showTime = !this.isSlotsRequired;
  // }

  
  panelVisible(){
    this.timestampInputDetails.inputValue = this.timestampInputDetails.inputValue == null ? this.handleLeadTime() : this.timestampInputDetails.inputValue; 
    this._journey.setTimestampDetails(this.timestampInputDetails.inputValue.getTime());
    if(this.isSlotsRequired){
      return ;
    }
    else{
      this.timestampInputDetails.minDate = this.handleLeadTime();
    }
  }

  timeStampCtaClick(){
    this._createRide.setIsExpressRide(false);

    if(this.isSlotsRequired){
     
      let selectedValue = this.timestampInputDetails.inputValue ? new Date(this.timestampInputDetails.inputValue) : null;
      
      let selectedDay = new Date(selectedValue).getDate();
      let selectedMonth = new Date(selectedValue).getMonth() + 1;
      let selectedYear = new Date(selectedValue).getFullYear();

      let selectedHours = this.hourDropDownDetails.selectedValue ? this.hourDropDownDetails.selectedValue.value : null;
      let selectedMinutes = this.minDropDownDetails.selectedValue ? this.minDropDownDetails.selectedValue.value : null;

      let selectedDate = new Date(`${selectedMonth}/${selectedDay}/${selectedYear},${selectedHours}:${selectedMinutes}`);

      this._journey.setTimestampDetails(selectedDate.getTime());

      if(selectedValue == null || selectedHours == '' || selectedMinutes == '' || selectedHours == null || selectedMinutes == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and slots to continue',
          ctaLabel: 'OK'
        });
        return ;
      }
      else{
        this._subscription.emitTriggerTimestampCtaObserver(true);
        return;
      }
      
    }

    else{
      if(this.timestampInputDetails.inputValue == null){
        this._commonSubscription.emitAlert({
          theme: Theme.WARNING,
          title: 'Select Date !',
          message: 'Please Select Date and time to continue',
          ctaLabel: 'OK'
        });
        return;
      }

      else{
        let name = this._customer.getCustomerDetails().firstName;
        let date = this._dateTimePipe.transform(this._journey.getTimestampDetails()).split(",")[0];
        let time = this._dateTimePipe.transform(this._journey.getTimestampDetails()).split(",")[1];


        this._commonSubscription.emitAlert({
          theme: Theme.INFO,
          title: 'Schedule Ride !',
          message: `${name}'s cab will be booked for ${date} at ${time}.`,
          ctaLabel: 'Schedule Now',
          cancelCta: true,
          cancelCtaLabel : 'Abort'
        })
        return;
      }
    }
  }


  startCloseDialogSubscription(){
    this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
      if(res[0]){
        this._subscription.emitTriggerTimestampCtaObserver(true);
      }
    })
  }


  requestExpressRide(){
    this._createRide.setIsExpressRide(true);
    this._journey.setTimestampDetails(new Date().getTime());

    let name = this._customer.getCustomerDetails().firstName;
    let date = this._dateTimePipe.transform(this._journey.getTimestampDetails(), false).split(",")[0];
    let time = this._dateTimePipe.transform(this._journey.getTimestampDetails(), false).split(",")[1];
    
    this._commonSubscription.emitAlert({
      theme: Theme.INFO,
      title: 'Book Express Ride !',
      message: `${name}'s cab will be booked for ${date} at ${time}.`,
      ctaLabel: 'Book Now',
      cancelCta: true,
      cancelCtaLabel : 'Abort'
    })
  }

  clearTimeStampDetails(){
    if(this.isSlotsRequired){
      this.hourDropDownDetails.selectedValue = {label: '', value: ''};
      this.minDropDownDetails.selectedValue = {label: '', value: ''};
      return;
    }
    else{
      return ;
    }
  }


  setTimestamp(date){
    if(this.isSlotsRequired){
      this.hourDropDownDetails.selectedValue = {label: '', value: ''};
      this.minDropDownDetails.selectedValue = {label: '', value: ''};
      this._journey.setTimestampDetails(null);
      this.fetchSlots(new Date(this.timestampInputDetails.inputValue).getTime());
    }
    else{
      this._journey.setTimestampDetails(new Date(this.timestampInputDetails.inputValue).getTime());
    }
  }


  fetchSlots(selectedDate){
  let x  = new Promise((resolve, reject)=>{

  let slotsDto = {
    ssoId : localStorage.getItem('ssoId'),
    priceMapId : this._journey.getPriceMapId() ? this._journey.getPriceMapId() : 0,
    zoneId : this._journey.getPickupLocationZoneId(),
    isScheduled : !this._createRide.isRentalRide(),
    isPremium : this._journey.getSubCategory() ? this._journey.getSubCategory().split("_")[0] == 'PREMIUM' : false,
    dateTime: new Date(this.timestampInputDetails.inputValue).getTime(),
    pickupLat: this._journey.getPickupLocation().lat,
    pickupLng: this._journey.getPickupLocation().lng,
    dropLat: this._journey.getDropLocation().lat || null,
    dropLng: this._journey.getDropLocation().lng || null,
  }
  
  this._loader.show();
  this._journeyApi.fetchSlots(slotsDto).subscribe(async res=>{
    this._loader.hide();
    if(res['statusCode'] == 200){
        this.allSlotsData = await res['response']['dateSlots'].length > 0 ? res['response']['dateSlots'][0]['timeGroupSlotsResponseList'] : {};
        if(Object.keys(this.allSlotsData).length < 1){
          this._commonSubscription.emitAlert({
            theme: Theme.ERROR,
            title: "No Slots Available!",
            message:  "All slots for the selected dates has been full",
            ctaLabel: 'Ok!'
          })
          this.hourDropDownDetails.isDisabled = true;
          reject(true);
        }
        else{
          this.hourDropDownDetails.options =  this.setHours(this.allSlotsData);
          this.hourDropDownDetails.isDisabled = false;
          resolve(true);
        }
    }
    else{
      this._commonSubscription.emitAlert({
        theme: Theme.ERROR,
        title: "Technical Error!",
        message: res['message'],
        ctaLabel: 'Ok!'
      });
      reject(true);
    }
  });
})
    return x;
  }
  

  setHours(timeSlots){
    let availableSlotsHours = [];
    let leadTime = this.handleLeadTime().getTime();
    timeSlots.forEach(timeGroup=>{
      Object.keys(timeGroup.slots).forEach(slot=>{
  
        if(timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && timeGroup['slots'][slot]['slotTime'] >= leadTime){
            let hour = slot.split(":")[0];
            if(availableSlotsHours.length < 1){
              availableSlotsHours.push({label : hour, value: hour});
            }
            else{
              let availableSlotsArr = availableSlotsHours.map(slots=>slots.value);
              if(!availableSlotsArr.includes(hour)){
                availableSlotsHours.push({label : hour, value: hour});
              }
              else{
                return;
              }
            }  
        }

        else{
          return;
        }
      });
    });
    return availableSlotsHours;
  }

  onHoursChange(){
   this.minDropDownDetails.options =  this.setMinutes(this.hourDropDownDetails.selectedValue.value, this.allSlotsData);
   if(this.minDropDownDetails.options.length < 1){
    this._commonSubscription.emitAlert({
      theme: Theme.WARNING,
      title: 'No Slots Available!',
      message: 'Slots are not available for given time',
      ctaLabel: 'Change Slot'
    });
    this.minDropDownDetails.isDisabled = true;
   }
   else{
    this.minDropDownDetails.isDisabled = false;
   } 
  }

  setMinutes(selectedHour, timeSlots){
      
      let availableSlotsMin = [];
      let leadTime = this.handleLeadTime().getTime();
      
      timeSlots.forEach(timeGroup=>{  
        Object.keys(timeGroup.slots).forEach(slot=>{
          if(slot.split(":")[0] == selectedHour && timeGroup['slots'][slot]['slotStatus'] == 'AVAILABLE' && timeGroup['slots'][slot]['slotTime'] >= leadTime){
            availableSlotsMin.push({label: slot.split(":")[1], value: slot.split(":")[1]});
          }
          else{
            return;
          }
        })
      });

      return availableSlotsMin;
  }

  handleLeadTime(){
    let startDate = new Date();
    let leadTime = this.getLeadTime();
    startDate.setMinutes(startDate.getMinutes() + leadTime);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    if(this._common.getIsAdminUser() || this._common.getBusinessSpecEligibleForAdmin()){
      return startDate;
    }

    else{
      let extraBuffer = this.getExtraBufferTime(startDate.getMinutes());
      extraBuffer == 0 ? startDate.setHours(startDate.getHours() + 1) : startDate.setHours(startDate.getHours());
      startDate.setMinutes(extraBuffer);
      return startDate;
    }    
  }

  getLeadTime(){
    if(this._common.getIsAdminUser()){
      return 0;
    }
    else {
      return this._common.getLeadTime();
    }
  }


  getExtraBufferTime(minVal){
    let buffer = 0;
    for(let i = 0; i < 60; i=i+this.timestampInputDetails.stepMinute){
      if(minVal >= i && minVal < i + this.timestampInputDetails.stepMinute ){  
        buffer = i + this.timestampInputDetails.stepMinute;
      }
      else{
        buffer = buffer;
      }
    }
    return buffer;
  }
  

  setMaxDate(){
    let maxDate = new Date();
      //changed the addition of days from 3 to 30 as per ThomasCook requirements
      maxDate.setDate(maxDate.getDate() + this._common.getMaxCreateRideDate());
      maxDate.setHours(23, 0, 0, 0);

      return maxDate;
  }

  fetchPrevData(){

  }

  ngOnDestroy(): void {
    this.closeDialogSubscription.unsubscribe();
  }
}

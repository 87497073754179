import { Component } from '@angular/core';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';
import { PaymentDetailsContainerService } from '../../getter-setter/container/create-ride/payment-details-container.service';
import { SharedRideCustomerDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { SharedRidePaymentDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-payment-details.service';
import { SharedRideJourneyDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { SharedRideContainerService } from '../../getter-setter/container/shared-ride/shared-ride-container.service';
import { CommonService } from '../../getter-setter/common.service';

@Component({
  selector: 'create-shared-ride-page',
  templateUrl: './create-shared-ride-page.component.html',
  styleUrls: ['./create-shared-ride-page.component.scss']
})
export class CreateSharedRidePageComponent {

  showSharedRideNew = false;
  constructor(private _customerDetails: SharedRideCustomerDetailsService,
              private _paymentDetails: SharedRidePaymentDetailsService,
              private _journeyDetails: SharedRideJourneyDetailsService,
              private _sharedRide: SharedRideContainerService,
              private _common: CommonService

  ){
  
    this.showSharedRideNew = _common.getIsShowSharedRideNew();
    _sharedRide.clearData()
    _sharedRide.setIsEdit(true);
    _sharedRide.setEditContainer('');
    _customerDetails.clearCustomerDetails();
    _journeyDetails.clearData();
    _paymentDetails.clearPaymentDetails()

    this._customerDetails.setDefaultCallingCode();
    this._paymentDetails.setAllowedPaymentModesDetails();
  }

  
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { SelectRideTypeContainerComponent } from './select-ride-type-container/select-ride-type-container.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../components/components.module';
import { CreateRideContainerComponent } from './create-ride-v2/create-ride-container.component';
import { CustomerDetailsContainerComponent } from './customer-details-container/customer-details-container.component';
import { CreateRideService } from '../getter-setter/container/create-ride/create-ride-container.service';
import { CommonService } from '../getter-setter/common.service';
import { JourneyDetailsContainerComponent } from './journey-details-container/journey-details-container.component';
import { LocationContainerComponent } from './journey-details-container/location-container/location-container.component';
import { CarVariantContainerComponent } from './journey-details-container/car-variant-container/car-variant-container.component';
import { AddLocationContainerComponent } from './journey-details-container/location-container/add-location-container/add-location-container.component';
import { AdditionalDetailsContainerComponent } from './journey-details-container/location-container/additional-details-container/additional-details-container.component';
import { PaymentDetailsContainerComponent } from './payment-details-container/payment-details-container.component';
import { CurrencyPipe, DateTimePipe, DaysOfWeekShort, OnlyDate, OnlyTime } from '../pipe/createridepipe.pipe';
import { HomepageContainerComponent } from './homepage-container/homepage-container.component';
import { PricingContainerComponent } from './homepage-container/pricing-container/pricing-container.component';
import { ServiceableRegionsContainerComponent } from './homepage-container/serviceable-regions-container/serviceable-regions-container.component';
import { FaqContainerComponent } from './homepage-container/faq-container/faq-container.component';
import { UsefulLinksContainerComponent } from './homepage-container/useful-links-container/useful-links-container.component';
import { WhatsNewContainerComponent } from './homepage-container/whats-new-container/whats-new-container.component';
import { PaymentDialogComponent } from 'src/app/main/container/payments/payment-dialog/payment-dialog.component';
import { FeedbackContainerComponent } from './feedback-container/feedback-container.component';
import { SharedRideJourneyDetailsContainerComponent } from './shared-ride-journey-details-container/shared-ride-journey-details-container.component';
import { SharedRidePaymentDetailsContainerComponent } from './shared-ride-payment-details-container/shared-ride-payment-details-container.component';
import { CreateSharedRideV2Component } from './create-shared-ride-v2/create-shared-ride-container.component';
import { SharedRideTimestampDetailsContainerComponent } from './shared-ride-journey-details-container/shared-ride-timestamp-details-container/shared-ride-timestamp-details-container.component';
import { SharedPickupContainerComponent } from './shared-ride-journey-details-container/shared-pickup-container/shared-pickup-container.component';
import { SharedDropContainerComponent } from './shared-ride-journey-details-container/shared-drop-container/shared-drop-container.component';
import { SharedRideAddLocationContainerComponent } from './shared-ride-journey-details-container/shared-ride-add-location-container/shared-ride-add-location-container.component';
import { SidebarModule } from 'primeng/sidebar';
import { SharedRideCustomerDetailsContainerComponent } from './shared-ride-customer-details-container/shared-ride-customer-details-container.component';
import { RecurringTimestampContainerComponent } from './journey-details-container/timestamp-container/recurring-timestamp-container/recurring-timestamp-container.component';
import { SingleTimestampContainerComponent } from './journey-details-container/timestamp-container/single-timestamp-container/single-timestamp-container.component';
import { FilterContainerComponent } from './filter-container/filter-container.component';
import { MultiSelectModule } from 'primeng/multiselect';





@NgModule({
  declarations: [
    SelectRideTypeContainerComponent,
    CreateRideContainerComponent,
    CustomerDetailsContainerComponent,
    JourneyDetailsContainerComponent,
    LocationContainerComponent,
    CarVariantContainerComponent,
    AddLocationContainerComponent,
    AdditionalDetailsContainerComponent,
    PaymentDetailsContainerComponent,
    DateTimePipe,
    DaysOfWeekShort,
    OnlyTime,
    OnlyDate,
    CurrencyPipe,
    HomepageContainerComponent,
    PricingContainerComponent,
    ServiceableRegionsContainerComponent,
    FaqContainerComponent,
    UsefulLinksContainerComponent,
    WhatsNewContainerComponent,
    FeedbackContainerComponent,
    CreateSharedRideV2Component,
    SharedRideJourneyDetailsContainerComponent,
    SharedRidePaymentDetailsContainerComponent,
    SharedRideTimestampDetailsContainerComponent,
    SharedPickupContainerComponent,
    SharedDropContainerComponent,
    SharedRideAddLocationContainerComponent,
    SharedRideCustomerDetailsContainerComponent,
    RecurringTimestampContainerComponent,
    SingleTimestampContainerComponent,
    FilterContainerComponent
  ],

  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    PaymentDialogComponent,
    SidebarModule,
    MultiSelectModule
  ],
  
  providers: [CreateRideService, CommonService],
  exports: [SelectRideTypeContainerComponent, CreateRideContainerComponent, CustomerDetailsContainerComponent, SharedRideAddLocationContainerComponent,
            JourneyDetailsContainerComponent, HomepageContainerComponent, FeedbackContainerComponent, CreateSharedRideV2Component, FilterContainerComponent]
})
export class ContainerModule { }
